<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Foto </strong><small>Tambah Data Baru</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputFoto">
            <CRow>
              <CCol sm="12">
                <CInput
                  v-model="jdl_gallery"
                  label="Judul Foto"
                  placeholder="Input Judul Foto"
                />
              </CCol>

              <CCol sm="6">
                <div class="form-group">
                <label>Album Foto</label>
                  <select class="form-control" v-model="album" >
                    <option value="" disabled selected>Pilih Album</option>
                    <option v-for="r in albums" :value="r.id_album">{{r.jdl_album}} 
                    </option>
                  </select>
                </div>
              </CCol>

              <CCol sm="6">
                <div class="form-group">
                  <label>FIle Foto </label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div> 
              </CCol>

              <CCol sm="12">
                <CTextarea
                  v-model="caption_gallery"
                  label="Caption Foto"
                  placeholder="Caption Foto"
                /> 
              </CCol>

              <CCol sm="6">
                <CInput
                  v-model="credit_gallery"
                  label="Credit Foto"
                  placeholder="Credit Foto"
                />
              </CCol>
                  
            </CRow>          
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/foto">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  data () {
    return {
      jdl_gallery : "",      
      caption_gallery : "",
      credit_gallery : "",
      gbr_gallery : "",
      gbr_gallery_text : "",
      album : "",
      placeholder : "Pilih File",
      albums : [], // Must be an array reference!
      selected : [], // Must be an array reference!
      show: true,
      modalError:false,
    }
  },
  mounted(){
  this.loadData();
  },

  methods: {

    loadData: function(event){

      axios.get(process.env.VUE_APP_BASE_URL+"albums")
        .then((response) => {
              this.albums=response.data;
        });
    },

    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.gbr_gallery_text = fileData.name;
      this.gbr_gallery = fileData;
      this.placeholder = fileData.name;
      // alert(fileData.name);
    },

    inputFoto: function(event){
      
      const formData = new FormData()      
      // formData.append('id_gallery', this.$route.params.id_gallery)
      formData.append('gbr_gallery', this.gbr_gallery, this.gbr_gallery.name)   
      formData.append('gbr_gallery_text', this.gbr_gallery.name)
      
      formData.append('jdl_gallery', this.jdl_gallery)
      formData.append('id_album', this.album)
      formData.append('caption_gallery', this.caption_gallery)
      formData.append('credit_gallery', this.credit_gallery)

      axios.post(process.env.VUE_APP_BASE_URL+"foto", formData )
        .then((response) => {
            if(response.data.status==='success'){
              this.$router.push('/foto');
            }
            else{
              this.modalError=true;
            }           
        })
    }
  }
}
</script>